<mat-toolbar class='footer' color='theme'>
  <div class='footer-text'>
    <p>Copyright &#169;
      <a routerLink='/get-involved'>FFBO Team</a>.
    </p>
  </div>
  <p class='footer-text-license'>
    All Rights Reserved.
    <a routerLink='/posts/licenses'> Licenses and Credits </a>
  </p>
  <p class='footer-text-credit'>
    Programmed and designed by
    <a href='https://github.com/TK-21st'> Tingkai Liu </a>
    .
  </p>
  <div class="flex-spacer"></div>
  <a routerLink='/posts/licenses' aria-label="license" class='footer-text-license-alt footer-icon'>
    <fa-icon [icon]="faScale"></fa-icon>
  </a>
  <a href='https://twitter.com/FlyBrainObs' aria-label="twitter" class='footer-icon'>
    <fa-icon [icon]="faTwitter"></fa-icon>
  </a>
  <a href='https://github.com/fruitflybrain' aria-label="github" class='footer-icon'>
    <fa-icon [icon]="faGithub"></fa-icon>
  </a>
</mat-toolbar>
